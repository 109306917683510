<template>
  <v-app>
     <v-banner single-line class="banner">
       <v-img
           src="https://h5.ophyer.cn/official_website/banner/yule-banner.png"
           height="100%"
           :transition="false"
           class="d-flex align-center"

       >
         <template v-slot:placeholder>
           <v-row class="fill-height ma-0" align="center" justify="center">
             <v-progress-circular
                 indeterminate
                 size="80"
                 color="red lighten-5"
             ></v-progress-circular>
           </v-row>
         </template>
          <v-container color="transparent" class="d-flex  max-width-1200">
           <v-card color="transparent" flat >
             <div class="d-flex flex-no-wrap justify-space-between ml-4">
               <div>
                 <v-card-title class="white--text h5-banner-title font-size-h5banner-title" >
                   VR直播
                 </v-card-title>
               </div>
             </div>
           </v-card>
         </v-container>
       </v-img>
     </v-banner>
    <!-- 产品功能 -->
    <v-card color="#fff" flat class="py-6 position-r">
      <v-card-title class="justify-center font-size-20 color-333 pa-0 mt-6 line-height-28">产品体系</v-card-title>
      <div class="h5-public-title">PRODUCT SYSTEM</div>
      <v-tabs v-model="tab" background-color="transparent" 
              class="xs-tab" style="margin-top:0px;">
        <v-tab v-for="(v, k) in data.wrap1.items" :key="k" class="pa-0"
               :class="[k === 0 ? 'ml-5':'ml-6',k === data.wrap1.items.length - 1 ? 'mr-5':'']"
               style="min-width: unset">
          <b class=" font-size-16 letter-spacing" style="font-weight:400;">{{ v.title }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items class="mt-3 mx-5" v-model="tab" style="border-radius: 10px;box-shadow: 0px 0px 18px 0px rgba(0, 92, 193, 0.16);">
        <v-tab-item v-for="(v, k) in data.wrap1.items" :key="k" :transition="false">
          <v-card flat color="white" class="pa-5">
            <v-img :src="v.src" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
            <v-card-title class="pa-0 mt-4 font-size-18 line-height-25">{{v.title}}</v-card-title>
              <v-card-text class="pa-0 my-1 line-height-26"  v-text="v.content" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <div class="tab-btn-prev" @click.stop="handleTabBtn('prev')"></div>
      <div class="tab-btn-next" @click.stop="handleTabBtn('next')"></div>
    </v-card>
    <!-- 产品优势 -->
    <!-- 三块布局 -->
    <v-card flat class="h5model_list1">
      <v-card-title class="justify-center font-size-20 color-333 pa-0 mt-6 line-height-28">产品优势</v-card-title>
      <div class="h5-public-title">PRODUCT ADVANTAGES</div>
      <div class="px-3">
        <v-row class="justify-center mt-0">
          <v-col v-for="(v,k) in modelList4" :key="k" cols="4" class="pa-0">
            <div class="model_item">
              <img class="item_img" :src='v.icon' />
              <div class="item_info">
                <v-card-title class="justify-center reset-title pt-2 font-size-16 color-333 font-weight-300">{{ v.title }}</v-card-title>
                <v-card-text class="reset-text reset-title font-size-12 color-999 px-2">{{ v.text }}</v-card-text>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-app>
</template>

<script>
import contentData from '@/static/contentData.js';
export default {
  name: 'h5SaasCloud',
  components: {
  },
  data() {
    return {
      model: 0,
      bannerLoad: false,
      imgLoad: false,
      data: contentData.vrLiveBroadcast,
      data1:contentData.VRSceneShow,
      tab: 0,
      modelList3: [
        {
          icon: "https://h5.ophyer.cn/official_website/other/saasadv-0.png",
          title: "链接稳定",
          text: "扎实的云端技术为您线上带看提供急速、稳定、专业的直播处理服务"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/other/saasadv-1.png",
          title: "在线互动",
          text: "主播通过弹幕与观众实时互动，可以根据观众的需求做出对应的讲解"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/other/saasadv-3.png",
          title: "量身定制",
          text: "根据需求，为您量身定制，完美还原场景，搭配专业的讲解词汇"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/other/saasadv-4.png",
          title: "场景",
          text: "电商，3D展馆，智慧运营，汽车在线观看工业，教育，平台不断挖掘更多场景，为更多行业赋能"
        }
      ],
      modelList4: [
        {
          icon: "https://h5.ophyer.cn/official_website/icon/yule-icon01.png",
          title: "链接稳定",
          text: "扎实的云端技术提供急速稳定的服务"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/icon/yule-icon02.png",
          title: "在线互动",
          text: "通过语音和文字实时互动"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/icon/yule-icon03.png",
          title: "量身定制",
          text: "量身定制720VR场景及专有数字人IP"
        },
      ],
    }
  },
  watch: {
  },
  created() {
  },
    mounted(){
    this.bannerLoad = true;
  },
  methods: {
    handleTabBtn: function (type){
      const maxTab = this.data.wrap1.items.length - 1;
      if(type === "prev"){
        if(this.tab === 0){
          this.tab = maxTab;
        }else {
          this.tab--;
        }
      }else if(type === "next"){
        if(this.tab === maxTab){
          this.tab = 0;
        }else {
          this.tab++;
        }
      }
    },
        handleLoad (){
      this.imgLoad = true;
    },
  }
}
</script>

<style lang="scss" scoped>
.h5model_list1 {
  width: 100%;
  position: relative;
  .model_item{
    width: 100%;
    background: #FFFFFF;
    border-radius: 16px;
    padding-bottom: 30px;
    text-align: center;
    .item_img{
      width: 80px;
      height: 80px;
      object-fit: contain;
    }
    .item_info{
      margin-top: -10px;
    }
  }
}
.warp {
  margin: 83px auto 0;
  text-align: center;
}
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  .banner-box {
    margin-top: -100px;
    .banner-text {
      p:nth-child(1) {
        font-size: 82px;
        //font-family: SourceHanSansCN-Regular;
        font-weight: 400;
        color: #fff;
        line-height: 88px;
      }
      p:nth-child(2) {
        margin-top: 25px;
        font-size: 24px;
        font-weight: 100 !important;
        color: #fff;
      }
      .banner-btn {
        margin-top: 90px;
        width: 200px;
        height: 60px;
        background: #0568FD;
        border-radius: 35px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  @media (min-width: 960px) {
    .container {
      max-width: 1200px !important;
    }
  }
  
}
.h5model_list3{
  width: 100%;
  position: relative;
  .model_item{
    width: 100%;
    min-height: 220px;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 0 10px;
    text-align: center;
    .item_img{
      width: 100px;
      height: 100px;
      object-fit: contain;
    }
    .item_info{
      margin-top: 8px;
    }
  }
}
</style>
